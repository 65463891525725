import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { FaBars, FaUser } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Assets/logo.jpg';
import Footer from './Footer';

const Menu = () => {
  const [menus, setMenus] = useState([]);
  const [isOpen, setIsOpen] = useState(false); // For mobile menu toggle
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false); // For user menu toggle
  const [activeTab, setActiveTab] = useState('Foods'); // Track the active tab
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const fetchMenus = useCallback(async () => {
    try {
      const res = await axios.get('https://ituzebe.onrender.com/api/menus', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMenus(res.data);
    } catch (err) {
      console.error(err);
    }
  }, [token]);

  useEffect(() => {
    fetchMenus();
  }, [fetchMenus]);

  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('Token');
    navigate('/signin');
  };

  const renderMenuItems = (type) => {
    return menus.filter(menu => menu.type === type).map(menu => (
      <div key={menu._id} className="flex justify-between mb-2 font-mono">
        <span>{menu.name}</span>
        <span>{menu.price}</span>
      </div>
    ));
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-yellow-100  text-white" style={{ fontFamily: '"Nunito", sans-serif' }}>
      <header className="sticky top-0 z-20 shadow-lg flex justify-between items-center p-4 bg-gradient-to-r from-orange-900 to-green-900 mx-5 my-2  mx-5 rounded-full backdrop-blur-lg bg-opacity-80 transition-all duration-300">
        <div className="flex items-center space-x-4 mx-5">
          <img src={logo} alt="Ituze Garden Palace Logo" className="h-12 w-12 rounded-full object-cover border-2 border-white shadow-md hover:scale-105 transition-transform duration-300" />
        </div>
        <div className="flex items-center space-x-6">
          <button onClick={handleNavbarToggle} className="text-2xl md:hidden text-white hover:text-red-500 transition-colors duration-300">
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className={`flex-col md:flex-row ${isOpen ? 'flex' : 'hidden'} md:flex`}>
            <ul className="block md:flex md:space-x-8 md:items-center text-white font-semibold md:ml-auto">
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/">Home</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/gallery">Gallery</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/reservation">Reservation</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/menu">Menu</Link></li>
              <li className="hover:underline hover:text-red-500 transition duration-300"><Link to="/contact">Contacts</Link></li>
            </ul>
          </nav>
          <div className="relative">
            <FaUser className="text-2xl text-white cursor-pointer hover:text-red-500 transition duration-300" onClick={() => setIsOpenUserMenu(!isOpenUserMenu)} />
            {isOpenUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 text-black z-30">
                <Link to="/profile" className="block px-4 py-2 hover:bg-gray-100 transition duration-200">Profile</Link>
                <button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition duration-200">Log Out</button>
              </div>
            )}
          </div>
        </div>
      </header>


      {/* Main Content */}
      <div className="min-h-screen mx-auto px-4 py-8 bg-white text-black">
        {/* Tabs for switching between Food and Drinks */}
        <div className="mb-8">
          <div className="flex justify-center space-x-4 mb-4">
            <button
              className={`py-2 px-4 font-bold ${activeTab === 'Foods' ? 'border-b-2 border-red-600' : ''}`}
              onClick={() => setActiveTab('Foods')}
            >
              Food Menu
            </button>
            <button
              className={`py-2 px-4 font-bold ${activeTab === 'Drinks' ? 'border-b-2 border-red-600' : ''}`}
              onClick={() => setActiveTab('Drinks')}
            >
              Drinks Menu
            </button>
          </div>

          <h2 className="text-2xl font-semibold mb-4">{activeTab} Menu</h2>
          {renderMenuItems(activeTab)}
        </div>
      </div>

     <Footer/>
    </div>
  );
};

export default Menu;
