import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaUser, FaTimes, FaEye } from 'react-icons/fa'; // Importing necessary icons
import logo from '../Assets/logo.jpg';
import Filter from './Filter';
import Footer from './Footer';

const GalleryCrud = () => {
  const [photos, setPhotos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isOpen, setIsOpen] = useState(false); // State for mobile navigation
  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false); // State for user menu
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get('https://ituzebe.onrender.com/api/photos/photos', {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setPhotos(res.data);
        const uniqueCategories = [
          ...new Set(res.data.map((photo) => photo.category)),
        ];
        setCategories(uniqueCategories);
      })
      .catch((err) => console.error(err));
  }, [token]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };


  const filteredPhotos = selectedCategory
    ? photos.filter((photo) => photo.category === selectedCategory)
    : photos;

  // Function to toggle mobile navigation
  const handleNavbarToggle = () => {
    setIsOpen(!isOpen);
  };

  // Function for logout
  const handleLogout = () => {
    localStorage.removeItem('Token');
    navigate('/signin');
  };

  return (
    <div
      className="min-h-screen bg-gradient-to-r from-yellow-100 text-white flex flex-col"
      style={{ fontFamily: '"Nunito", sans-serif' }}
    >
      <header className="sticky top-0 z-20 shadow-lg flex justify-between items-center p-4 bg-gradient-to-r from-orange-900 to-green-900 mx-5 my-2 mx-5 rounded-full backdrop-blur-lg bg-opacity-80 transition-all duration-300">
        <div className="flex items-center space-x-4 mx-5">
          <img
            src={logo}
            alt="Ituze Garden Palace Logo"
            className="h-12 w-12 rounded-full object-cover border-2 border-white shadow-md hover:scale-105 transition-transform duration-300"
          />
        </div>
        <div className="flex items-center space-x-6">
          <button
            onClick={handleNavbarToggle}
            className="text-2xl md:hidden text-white hover:text-red-500 transition-colors duration-300"
          >
            {isOpen ? <FaTimes /> : <FaBars />}
          </button>
          <nav className={`flex-col md:flex-row ${isOpen ? 'flex' : 'hidden'} md:flex`}>
            <ul className="block md:flex md:space-x-8 md:items-center text-white font-semibold md:ml-auto">
              <li className="hover:underline hover:text-red-500 transition duration-300">
                <Link to="/">Home</Link>
              </li>
              <li className="hover:underline hover:text-red-500 transition duration-300">
                <Link to="/gallery">Gallery</Link>
              </li>
              <li className="hover:underline hover:text-red-500 transition duration-300">
                <Link to="/reservation">Reservation</Link>
              </li>
              <li className="hover:underline hover:text-red-500 transition duration-300">
                <Link to="/menu">Menu</Link>
              </li>
              <li className="hover:underline hover:text-red-500 transition duration-300">
                <Link to="/contact">Contacts</Link>
              </li>
            </ul>
          </nav>
          <div className="relative">
            <FaUser
              className="text-2xl text-white cursor-pointer hover:text-red-500 transition duration-300"
              onClick={() => setIsOpenUserMenu(!isOpenUserMenu)}
            />
            {isOpenUserMenu && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 text-black z-30">
                <Link
                  to="/profile"
                  className="block px-4 py-2 hover:bg-gray-100 transition duration-200"
                >
                  Profile
                </Link>
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100 transition duration-200"
                >
                  Log Out
                </button>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Main content */}
      <div className="container mx-auto px-4 py-8 bg-gradient-to-r from-yellow-100 ">
        <div className="mb-8 text-black">
          <h1 className="text-4xl font-semibold mb-4 text-center">Explore Our Gallery</h1>
          <Filter
            categories={categories}
            selectedCategory={selectedCategory}
            handleCategoryChange={handleCategoryChange}
          />
        </div>

        {/* Display Photos */}
        {filteredPhotos.length > 0 ? (
          <div>
            <h2 className="text-3xl font-semibold text-yellow-800 mb-4 text-center">
              {selectedCategory ? `${selectedCategory} Photos` : 'All Photos'}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              {filteredPhotos.map((photo) => (
                <div key={photo._id} className="bg-white rounded-lg overflow-hidden shadow-lg">
                  <img
                    src={photo.imageUrl}
                    alt={photo.title}
                    className="w-full h-64 object-cover"
                  />
                  <div className="p-4">
                    <h3 className="text-lg font-semibold">{photo.title}</h3>
                    <p className="text-gray-600">{photo.description}</p>
                    <p className="text-sm text-gray-500 mt-2">{photo.category}</p>
                    <div className="flex justify-end mt-4 space-x-4">
                      <Link
                        to={`/photo/${photo._id}`}
                        className="text-blue-500 hover:text-blue-700 flex items-center focus:outline-none"
                        title="View Photo"
                      >
                        <FaEye className="mr-2" /> View More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-xl">No photos found.</p>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default GalleryCrud;
